<template>
    <div class="">
        
        <form 
            class="panel"
            @submit.prevent="onUserInvite()">
            
            <legend class="mt-0">{{$t('settings.users.inviteUsers')}}</legend>
            
            <!-- <div class="md:grid gap-4 grid-cols-2">
                <InputSingleline 
                    v-model="invitation.email"
                    type="email"
                    :label="$t('auth.email')"
                    :required="true"
                />
                
                <div>
                    <label for="">User role</label>
                    <select 
                        v-model="invitation.role"
                        class="">
                        
                        <option value="admin">{{$t('settings.users.roles.administrator')}}</option>
                        <option value="guest">{{$t('settings.users.roles.guest')}}</option>
                    </select>
                </div>
            </div> -->
            
            <InputSingleline 
                v-model="invitation.email"
                type="email"
                :label="$t('auth.email')"
                :required="true"
            />
            
            <!-- <InputSingleline 
                v-model="invitation.subject"
                :label="$t('settings.users.invitation.subject')"
                :required="true"
            />
            
            <InputMultiline
                v-model="invitation.body"
                type="email"
                :label="$t('settings.users.invitation.body')"
                :required="true"
                classes="h-64"
            /> -->
            
            <button 
                class="button submit"
                :class="{'loading' : loading == 'inviting'}">
                
                {{$t('settings.users.sendInvitation')}}
            </button>
            
        </form>
        
        <div class="panel mt-12">
            <legend class="mt-0">{{$t('settings.users.accountUsers')}}</legend>
            
            <div 
                v-if="!users"
                class="my-4 flex items-center justify-center">
                
                <div class="spinner"></div>
            </div>
            <div 
                v-if="users"
                class="
                    my-4 
                    grid 
                    gap-4 
                    md:gap-8 
                    grid-cols-1
                    2xl:grid-cols-2
                ">
                
                <div 
                    v-for="user in users"
                    :key="user.Username"
                    class="border bg-white p-4 flex rounded-lg relative items-start justify-center">
                    
                    <!-- <i 
                        class="absolute p-2 fas fa-trash m-1 top-0 right-0 text-sm text-gray-500"
                        :title="$t('settings.users.removeUser')"
                        @click="removeUser(user)"
                    />
                    -->
                    
                    <div class="flex-none w-20 h-20 mr-6 flex items-center justify-center bg-gray-300 text-white rounded">
                        <i 
                            class="fas text-4xl" 
                            :class="account.owner.username == user.Username ? 'fa-user-shield' : 'fa-user-tie'"
                        />
                    </div>
                    
                    <div class="flex-grow">
                        <div class="text-black">
                            {{user.Attributes.find(a => a.Name == 'email').Value}}
                            <span 
                                v-if="user.Username == getUser.username"
                                class="ml-2 py-0 px-1 bg-gray-400 text-white rounded text-sm">
                                {{$t('settings.users.you')}}
                            </span>
                        </div>
                        
                        <div v-if="account.owner.username == user.Username">
                            {{$t('settings.users.roles.owner')}}
                        </div>
                        <div v-else>
                            {{$t('settings.users.roles.administrator')}}
                        </div>
                        
                        <div v-if="user.UserStatus != 'CONFIRMED'">
                            {{ $t('auth.userStatusUnconfirmed') }}
                            <!-- {{user.UserStatus == 'CONFIRMED' ? $t('auth.userStatusConfirmed') : $t('auth.userStatusUnconfirmed')}} -->
                        </div>
                        
                        
                        <div 
                            v-if="user.info"
                            class="">
                            
                            <div>
                                {{user.info.firstname}} {{user.info.lastname}}
                            </div>
                            <div>
                                {{user.info.title}}
                            </div>
                            <div>
                                {{user.info.phonenumber}}
                            </div>
                        </div>
                        
                        
                        <a 
                            v-if="user.Username == getUser.username"
                            class="link block mt-2"
                            @click="userInfo = {...user.info}; showUserInfo = true;">
                            
                            {{ $t('settings.users.editPersonalInfo') }}
                        </a>
                        
                        
                        
                        <Modal 
                            v-if="showUserInfo"
                            @close="showUserInfo = false">
                            
                            <header slot="header">
                                <div class="py-4 px-6">
                                    {{$t('settings.users.personalInfoHeading')}}
                                </div>
                            </header>
                            
                            <form 
                                class="pb-6 px-6"
                                @submit.prevent="onUserSave()">
                                
                                
                                <InputSingleline 
                                    v-model="userInfo.firstname"
                                    type="text"
                                    :label="$t('settings.users.firstname')"
                                />
                                
                                <InputSingleline 
                                    v-model="userInfo.lastname"
                                    type="text"
                                    :label="$t('settings.users.lastname')"
                                />
                                
                                <InputSingleline 
                                    v-model="userInfo.title"
                                    type="text"
                                    :label="$t('settings.users.title')"
                                />
                                
                                <InputSingleline 
                                    v-model="userInfo.phonenumber"
                                    type="text"
                                    :label="$t('settings.users.phonenumber')"
                                />
                                
                                <footer slot="footer">
                                    <div class="buttons-wrapper">
                                        <a 
                                            class="button transparent link" 
                                            @click="showUserInfo = false">
                                            
                                            {{ $t('general.cancel') }}
                                        </a>
                                        
                                        <button 
                                            type="submit"
                                            class="button submit"
                                            :class="{'loading' : loading == 'savingUser'}">
                                            
                                            {{ $t('general.save') }}
                                        </button>
                                    </div>
                                </footer>
                            </form>
                        </Modal>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                loading: null,
                invitation: {
                    role: 'admin',
                    email: '',
                    locale: this.$i18n.locale,
                },
                invitationResponse: null,
                showUserInfo: false,
                userInfo: {
                    firstname: null,
                    lastname: null,
                    title: null,
                    phonenumber: null,
                }
            }
        },
        
        computed: {
            getUser(){
                return this.$store.getters.getUser;
            },
            
            users(){
                return this.$store.getters.getUsers;
            }
        },
        
        methods: {
            emailIsValid(email) {
                return /\S+@\S+\.\S+/.test(email)
            },
            
            async onUserInvite(){
                try {
                    if (
                        this.invitation.email == '' || 
                        !this.emailIsValid(this.invitation.email) ) {
                        
                        throw Error( this.$t('settings.users.needValidEmail') );
                    }
                    
                    this.loading = 'inviting';
                    
                    this.invitationResponse = await this.accountUsersInvite( {
                        invitation: this.invitation,
                        account: this.account,
                    });
                    
                    await this.$store.dispatch('listUsers');
                    
                    this.$notify({ type: 'success', text: this.$t('settings.users.invitedNewUserByEmail') });
                    this.invitation.email = '';
                } 
                catch (err) {
                    console.log(err);
                    this.$notify( {type: 'error', text: err.message} );
                }
                finally{
                    this.loading = null;
                }
            },
            
            async onUserSave(){
                try {
                    this.loading = 'savingUser';
                    
                    await this.$store.dispatch('updateUser', this.userInfo);
                    
                    this.$notify({ type: 'success', text: this.$t('general.updated') });
                    this.showUserInfo = false;
                } 
                catch (err) {
                    console.log(err);
                    this.$notify( {type: 'error', text: err.message} );
                }
                finally{
                    this.loading = null;
                }
            },
        },
    }
</script>
