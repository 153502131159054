<template>
    <div class="h-full w-full flex flex-col">
    
        <div 
            class="
                flex-none 
                flex 
                items-center
                flex-col
                
                md:hidden
            ">
            
            <div 
                class="
                    flex items-center justify-center
                    h-12 
                    w-full
                    bg-clay
                    md:bg-transparent
                    
                    md:h-auto
                    md:w-auto
                ">
                
                <button 
                    class="button transparent mr-2 absolute left-0"
                    @click="toggleSettingsNavigation()">
                     
                    <i v-if="!showSettingsNavigation" class="fas fa-bars text-white text-xl" />
                    <i v-else class="fas fa-times text-white text-xl" />
                </button>
                
                <h1 class="text-white md:text-black ">
                    {{ $t( 'settings.settings' )}}
                </h1>
            </div>
        </div>
        
        <div class="flex-grow w-full flex overflow-y-auto">
            
            <div class="
                flex-none h-full bg-gray-100 leading-loose hidden md:flex flex-col
                py-12 px-8
                2xl:py-12 2xl:px-16
            ">
                <div class="flex-grow">
                    <div 
                        v-for="mainSection in sections"
                        :key="'subSections_'+mainSection.name">
                        
                        <h3 class="text-black">{{mainSection.name}}</h3>
                        
                        <nav class="mb-8">
                            <router-link 
                                v-for="sectionCode in mainSection.subSections"
                                :key="'subSections_'+mainSection.name+'_'+sectionCode"
                                :to="{ name: 'settings-section', params: {section: sectionCode} }">
                                
                                {{ $t( 'settings.'+sectionCode+'.heading' )}}
                            </router-link>
                        </nav>
                    </div>
                </div>
                <div
                    v-if="appVersion && appVersion.version"
                    class="flex-none opacity-50 text-xs">
                    
                    Version {{appVersion.version}} {{appVersion.stage}}
                </div>
            </div>
            
            <form 
                v-if="accountEdit"
                @submit.prevent="updateAccount()"
                class="flex-grow flex flex-col">
                
                <div 
                    class="
                        flex-none flex items-center 
                        h-20
                        px-4 
                        
                        md:h-32
                        md:py-8
                        md:px-16
                        md:flex-row
                    ">
                    <h2 class="text-black m-0 flex-grow">
                        {{ section ? $t( 'settings.'+section+'.heading' ) : ''}}
                    </h2>
                    
                    <button 
                        v-if="section != 'users'"
                        :disabled="loading"
                        type="submit"
                        class="button submit"
                        :class="{'loading' : loading == 'update'}">
                         
                        <i class="fas fa-check mr-2 " />
                        {{ $t('general.save') }}
                     </button>
                </div>
                
                <div 
                    class="
                        flex-grow overflow-y-auto
                        px-4 pb-4
                        md:px-16 md:pb-16
                    ">
                    
                    <section v-if="section == 'accountDetails' || !section" class="panel">
                        
                        <!-- accountName -->
                            <label>
                                {{ $t( 'settings.accountDetails.accountName' ) }}
                                
                                <i 
                                    class="fas fa-question-circle link" 
                                    @click="helpSection = 'name'; iframeScroll('section_name')"
                                />
                            </label>
                            
                            <div 
                                v-if="helpSection == 'name'"
                                class="helpSection">
                                
                                {{ $t( 'settings.accountDetails.accountNameHelp' ) }}
                            </div>
                            
                            <input 
                                v-model="accountEdit.name"
                                type="text"
                                :required="true"
                            />
                        
                        
                        <!-- about -->
                            <label>
                                {{ $t( 'settings.accountDetails.about' ) }}
                                
                                <i 
                                    class="fas fa-question-circle link" 
                                    @click="helpSection = 'about'; iframeScroll('section_about')"
                                />
                            </label>
                            
                            <div 
                                v-if="helpSection == 'about'"
                                class="helpSection">
                                
                                {{ $t( 'settings.accountDetails.aboutHelp' ) }}
                            </div>
                            
                            <textarea 
                                v-model="accountEdit.about"
                                class="h-64"
                            />
                        
                        
                        <!-- video -->
                            <label>
                                {{ $t( 'settings.accountDetails.videoUrl' ) }}
                                
                                <i 
                                    class="fas fa-question-circle link" 
                                    @click="helpSection = 'videoUrl'; iframeScroll('section_videoUrl')"
                                />
                            </label>
                            
                            <div 
                                v-if="helpSection == 'videoUrl'"
                                class="helpSection">
                                
                                {{ $t( 'settings.accountDetails.videoUrlHelp' ) }}
                            </div>
                            
                            
                            <!-- @change="checkVideoUrl" -->
                            <input 
                                v-model="accountEdit.video"
                                @input="checkVideoUrl"
                                @change="checkVideoUrl"
                                type="text"
                                :required="false"
                            />
                            
                            <div 
                                v-if="accountEdit.video"
                                class="mt-12"
                                style="
                                    position: relative;
                                    padding-bottom: 56.25%;
                                    height: 0;
                                    overflow: hidden;
                                ">
                                
                                <iframe 
                                    :src="accountEdit.video" 
                                    frameborder="0" 
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                    allowfullscreen
                                    style="
                                        position: absolute;
                                        top: 0; 
                                        left: 0;
                                        width: 100%;
                                        height: 100%;
                                    ">
                                </iframe>
                            </div>
                            
                            
                            <!-- <InputSingleline 
                                v-model="accountEdit.video"
                                :required="false"
                                :label="$t( 'settings.accountDetails.videoUrl' )"
                                :name="'video'"
                            /> -->
                    </section>
                    <section v-else-if="section == 'contactDetails'" class="panel">
                        <div 
                            v-if="!helpSection || helpSection != 'contact'"
                            @click="helpSection = 'contact'; iframeScroll('section_contact')"
                            class="helpSection">
                            
                            <i class="fas fa-question-circle link" /> {{ $t( 'settings.contactDetails.helpHeading' ) }}
                        </div>
                        
                        <div 
                            v-else
                            class="helpSection">
                            
                            {{ $t( 'settings.contactDetails.helpText' ) }}
                        </div>
                        
                        <InputSingleline 
                            v-model="accountEdit.contact.street"
                            :label="$t( 'settings.contactDetails.street' )"
                            :name="'street'"
                        />
                        
                        <div class="flex">
                            <InputSingleline 
                                v-model="accountEdit.contact.postcode"
                                :label="$t( 'settings.contactDetails.postcode' )"
                                :name="'postcode'"
                                class="w-1/3 mr-2"
                            />
                            
                            <InputSingleline 
                                v-model="accountEdit.contact.city"
                                :label="$t( 'settings.contactDetails.city' )"
                                :name="'city'"
                                class="w-2/3 ml-2"
                            />
                        </div>
                        
                        <InputSingleline 
                            v-model="accountEdit.contact.country"
                            :label="$t( 'settings.contactDetails.country' )"
                            :name="'country'"
                            class="w-full"
                        />
                        
                        <div class="flex">
                            <InputSingleline 
                                v-model="accountEdit.contact.email"
                                :label="$t( 'settings.contactDetails.email' )"
                                :name="'email'"
                                class="flex-1 mr-2"
                            />
                            
                            <InputSingleline 
                                v-model="accountEdit.contact.phone"
                                :label="$t( 'settings.contactDetails.phone' )"
                                :name="'phone'"
                                class="flex-1 ml-2"
                            />
                        </div>
                        
                        <div class="flex">
                            <InputSingleline 
                                v-model="accountEdit.contact.webpage"
                                :label="$t( 'settings.contactDetails.webPage' )"
                                :name="'webPage'"
                                class="flex-1 mr-2"
                            />
                            
                            <InputSingleline 
                                v-model="accountEdit.contact.facebook"
                                :label="$t( 'settings.contactDetails.facebook' )"
                                :name="'facebook'"
                                class="flex-1 ml-2"
                            />
                        </div>
                    </section>
                    <section v-else-if="section == 'branding'" class="panel">
                        <div class="grid md:grid-cols-2 md:gap-4">
                            <div>
                                <label>{{$t('settings.branding.mainColor')}}</label>
                                <p>{{$t('settings.branding.mainColorText')}}</p>
                                
                                <div class="relative ">
                                    <input 
                                        type="text"
                                        style="padding-left: 3rem;"
                                        v-model="accountEdit.brand.colorMain"
                                    />
                                    <v-swatches 
                                        class="w-8 h-8 m-2"
                                        style="position: absolute; top: 0; left: 0;"
                                        v-model="accountEdit.brand.colorMain"
                                        popover-x="right"
                                        swatches="text-advanced">
                                        
                                        <div 
                                            slot="trigger"
                                            class="w-8 h-8 border rounded" 
                                            :style="'background: '+accountEdit.brand.colorMain"
                                        />
                                    </v-swatches>
                                </div>
                            </div>
                            <div>
                                <label>{{$t('settings.branding.linkColor')}}</label>
                                <p>{{$t('settings.branding.linkColorText')}}</p>
                                
                                <div class="relative ">
                                    <input 
                                        type="text"
                                        style="padding-left: 3rem;"
                                        v-model="accountEdit.brand.colorLinks"
                                    />
                                    <v-swatches 
                                        class="w-8 h-8 m-2"
                                        style="position: absolute; top: 0; left: 0;"
                                        v-model="accountEdit.brand.colorLinks"
                                        popover-x="right"
                                        swatches="text-advanced">
                                        
                                        <div 
                                            slot="trigger"
                                            class="w-8 h-8 border rounded " 
                                            :style="'background: '+accountEdit.brand.colorLinks"
                                        />
                                    </v-swatches>
                                </div>
                            </div>
                        </div>
                        
                        <label>{{$t('settings.branding.brandLogo')}}</label>
                        
                        <div 
                            v-if="accountEdit.brand.logo"
                            class="w-full border p-8 object-contain bg-gray-100 relative">
                            
                            <img 
                                :src="fileToUrl(accountEdit.brand.logo)"
                                alt="Logo"
                                class="object-contain h-32 mx-auto"
                                style="max-width: 20rem; "
                            />
                            
                            <div class="absolute top-0 right-0 m-2">
                                <a 
                                    :href="fileToUrl(accountEdit.brand.logo)"
                                    target="_blank"
                                    download>
                                    
                                    <i 
                                        class="bg-white p-2 fas fa-download" 
                                        :title="$t('settings.branding.downloadLogo')"
                                    />
                                </a>
                                <i 
                                    class="bg-white p-2 fas fa-trash ml-2"
                                    :title="$t('settings.branding.removeLogo')"
                                    @click="accountEdit.brand.logo = null"
                                />
                            </div>
                        </div>
                        
                        <InputUpload
                            v-else
                            :name="'logo'"
                            :multiple="false"
                            
                            @input="onImageAdded($event)"
                        />
                        
                        <div 
                            class="p-16 my-8 rounded text-center"
                            style="background: #f9f9f6">
                            
                            <div class="max-w-md m-auto">
                                <span class="text-black">Need help with your brand?</span>
                                <h1 class="text-4xl text-black font-bold leading-none">Super simple design and marketing tool</h1>
                                
                                <p class="mt-4 mb-8">
                                    Design social media posts, posters and ads right here in the browser. We believe anyone can master it!
                                    And if you're stuck, we got designers ready to help you out
                                </p>
                                
                                <a 
                                    class="hover:opacity-75 px-8 py-4 rounded-full text-white mt-2 inline-block"
                                    style="background: #2ebd59;"
                                    href="https://brandapp.io"
                                    target="_blank">
                                    Start Free Now
                                </a>
                            </div>
                        </div>
                        
                    </section>
                    <section v-else-if="section == 'localeSettings'" class="panel">
                        <label for="language">{{$t('settings.localeSettings.language')}}</label>
                        
                        <select 
                            v-model="accountEdit.locale.language"
                            @change="onChangeLocale(accountEdit.locale.language)"
                            id="language">
                            
                            <option 
                                v-for="language in languages"
                                :key="language.name"
                                :value="language.code">
                                
                                {{language.name}}
                            </option>
                        </select>
                        
                        
                        <!-- currency -->
                        <label>
                            {{$t('settings.localeSettings.currency')}}
                            
                            <i 
                                class="fas fa-question-circle link" 
                                @click="helpSection = 'total'; iframeScroll('section_total')"
                            />
                        </label>
                        
                        <div 
                            v-if="helpSection == 'total'"
                            class="helpSection">
                            
                            {{ $t( 'settings.localeSettings.currencyHelp' ) }}
                        </div>
                        
                        <select v-model="accountEdit.locale.currency">
                            <option 
                                v-for="currency in currencies"
                                :key="currency.name"
                                :value="currency.code">
                                
                                {{currency.name}} ({{currency.code}})
                            </option>
                        </select>
                        
                        <!-- <LocaleSwitcher /> -->
                        
                        <!-- 
                        <div class="mt-24">
                            <div class="bg-blue-100 p-4">
                                
                                <pre>{{$i18n.locale}}</pre>
                                
                                <hr>
                                
                                <p>{{ $t('main.message', {account: 'Ecode AS'}) }}</p>
                                <p>Account is: {{ $t('main.account') }}</p>
                                            
                            </div>
                            
                            <div class="grid grid-cols-2 gap-8 ">
                                
                                <div class="bg-blue-300 p-4 flex-1">
                                    <p>{{ $t('main.message', {account: 'Ecode AS'}) }}</p>
                                    <p>Account is: {{ $t('main.account') }}</p>
                                                
                                    <p>{{ $tc('main.apple', appleCount) }}</p>
                                    
                                    <button class="button submit mr-1" @click="appleCount">-</button>
                                    <button class="button submit" @click="appleCount++">+</button>
                                </div>
                                
                                <div class="bg-yellow-300 p-4 flex-1">
                                    <h2>Date Formating:</h2>
                                    
                                    Default
                                    <p>{{ $d(new Date(), 'short') }}</p>
                                    <p>{{ $d(new Date(), 'long') }}</p>
                                    
                                    en:
                                    <p>{{ $d(new Date(), 'short', 'en') }}</p>
                                    <p>{{ $d(new Date(), 'long', 'en') }}</p>
                                    
                                    no:
                                    <p>{{ $d(new Date(), 'short', 'nb') }}</p>
                                    <p>{{ $d(new Date(), 'long', 'nb') }}</p>
                                    
                                    es:
                                    <p>{{ $d(new Date(), 'short', 'es') }}</p>
                                    <p>{{ $d(new Date(), 'long', 'es') }}</p>
                                    
                                </div>
                                
                                <div class="bg-red-300 p-4 flex-1">
                                    <h2>Currency / Numbers </h2>
                                    
                                    Regular number: <br>
                                    <i18n-n :value="100" /><br>
                                    
                                    Currency number: <br>
                                    <i18n-n :value="100" format="currency" /><br>
                                    
                                    Spanish currency number: <br>
                                    <i18n-n :value="100" format="currency" locale="es" /><br>
                                </div>
                                
                                <div class="bg-grey-300 p-4 flex-1">
                                    <label>{{ $t('message.homeAddress') }}</label>
                                    <p class="error">{{ $t('message.missingHomeAddress') }}</p>
                                </div>
                            </div>
                            
                        </div> -->
                        
                    </section>
                    <section v-else-if="section == 'users'">
                        <Users />
                        
                        
                        <!-- 
                        <div 
                            v-if="getUser"
                            class="text-center">
                            
                            <div class="my-8">
                                {{ getUser.attributes.email }}
                            </div>
                            
                            <button 
                                @click.prevent="signOut()"
                                class="button link submit">
                                
                                {{ $t('auth.signOut') }}
                            </button>
                        </div> -->
                    </section>
                    
                    <section v-else-if="section == 'integrations'">
                        <Integrations :accountEdit="accountEdit" />
                    </section>
                    <section v-else-if="section == 'general'" class="panel">
                        <!-- <InputSelect 
                            v-model="accountEdit.industry"
                            :required="true"
                            :label="$t('settings.general.industry')"
                            :name="'industry'"
                            
                            :options="industries"
                        /> -->
                        
                        <InputMultiline 
                            v-model="accountEdit.notes"
                            :label="$t('settings.general.notes')"
                            :name="'notes'"
                            classes="h-64"
                        />
                    </section>
                    <section v-else-if="section == 'offerSettings'" class="panel">
                        
                        <!-- offerHeading -->
                        <label>
                            {{ $t('settings.offerSettings.offerHeading') }}
                            
                            <i 
                                class="fas fa-question-circle link" 
                                @click="helpSection = 'heading'; iframeScroll('section_offerHeading')"
                            />
                        </label>
                        
                        <div 
                            v-if="helpSection == 'heading'"
                            class="helpSection">
                            
                            {{ $t('settings.offerSettings.offerHeadingHelp') }}
                        </div>
                        
                        <input 
                            v-model="accountEdit.offer.heading"
                            type="text"
                        />
                        
                        
                        
                        <!-- offerText -->
                        <label>
                            {{ $t('settings.offerSettings.offerText') }}
                            
                            <i 
                                class="fas fa-question-circle link" 
                                @click="helpSection = 'offerText'; iframeScroll('section_offerText')"
                            />
                        </label>
                        
                        <div 
                            v-if="helpSection == 'offerText'"
                            class="helpSection">
                            
                            {{ $t('settings.offerSettings.offerTextHelp') }}
                        </div>
                        
                        <textarea 
                            v-model="accountEdit.offer.text"
                            class="h-64"
                        />
                        
                        
                        
                        
                        <!-- acceptButton -->
                        <label>
                            {{ $t('settings.offerSettings.acceptButton') }}
                            
                            <i 
                                class="fas fa-question-circle link" 
                                @click="helpSection = 'acceptButton'; iframeScroll('section_acceptButton')"
                            />
                        </label>
                        
                        <div 
                            v-if="helpSection == 'acceptButton'"
                            class="helpSection">
                            
                            {{ $t('settings.offerSettings.acceptButtonHelp') }}
                        </div>
                        
                        <input 
                            v-model="accountEdit.offer.acceptButton"
                            type="text"
                        />
                        
                        
                        
                        
                        
                        
                        <!-- declineButton -->
                        <label>
                            {{ $t('settings.offerSettings.declineText') }}
                            
                            <i 
                                class="fas fa-question-circle link" 
                                @click="helpSection = 'declineButton'; iframeScroll('section_declineButton')"
                            />
                        </label>
                        
                        <div 
                            v-if="helpSection == 'declineButton'"
                            class="helpSection">
                            
                            {{ $t('settings.offerSettings.declineTextHelp') }}
                        </div>
                        
                        <input 
                            v-model="accountEdit.offer.declineText"
                            type="text"
                        />
                        
                        
                        
                        <!-- <legend>Accept conditions</legend> -->
                        
                        <!-- acceptConditionsLink -->
                        <label>
                            {{ $t('settings.offerSettings.acceptConditionsLink') }}
                            
                            <i 
                                class="fas fa-question-circle link" 
                                @click="helpSection = 'acceptConditionsLink'; iframeScroll('section_acceptLegal')"
                            />
                        </label>
                        
                        <div 
                            v-if="helpSection == 'acceptConditionsLink'"
                            class="helpSection">
                            
                            {{ $t('settings.offerSettings.acceptConditionsLinkHelp') }}
                        </div>
                        
                        <input 
                            v-model="accountEdit.offer.acceptConditionsLink"
                            type="text"
                        />
                        
                         
                         
                         
                         
                         
                         
                        
                        
                       
                       <!-- acceptLegalText -->
                       <label>
                           {{ $t('settings.offerSettings.acceptLegalText') }}
                           
                           <i 
                               class="fas fa-question-circle link" 
                               @click="helpSection = 'acceptLegal'; iframeScroll('section_acceptLegal')"
                           />
                       </label>
                       
                       <div 
                           v-if="helpSection == 'acceptLegal'"
                           class="helpSection">
                           
                           {{ $t('settings.offerSettings.acceptLegalTextHelp') }}
                       </div>
                       
                       <textarea 
                           v-model="accountEdit.offer.acceptLegal"
                           class="h-64"
                       />
                       
                       
                       
                       <label class="mt-4 mb-8">
                            <input 
                                class="mr-1"
                                type="checkbox" 
                                v-model="accountEdit.offer.acceptLegalCheck"
                            />
                            
                            {{$t('settings.offerSettings.acceptLegalCheck')}}
                        </label>
                        
                    </section>
                    <section v-else-if="section == 'offerSendOptions'" class="panel">
                        <legend class="text-black">{{$t('settings.offerSendOptions.intitalSending')}}</legend>
                        
                        <InputSingleline
                            v-model="accountEdit.send.subject"
                            :label="$t('settings.offerSendOptions.offerEmailSubject')"
                            :name="'subject'"
                        />
                        
                        <!-- <InputMultiline 
                            v-model="accountEdit.send.body"
                            :label="$t('settings.offerSendOptions.offerMessageBody')"
                            :name="'body'"
                            classes="h-64"
                        /> -->
                        
                        
                        
                        <label>
                            {{ $t('settings.offerSendOptions.offerMessageBody') }}
                            
                            <i 
                                class="fas fa-question-circle link" 
                                @click="helpSection != 'offerMessageBody' ? helpSection = 'offerMessageBody' : helpSection = null;"
                            />
                        </label>
                        
                        <div 
                            v-if="helpSection == 'offerMessageBody'"
                            class="helpSection"
                            v-html="$t('settings.offerSendOptions.offerMessageBodyHelp')+'<br>{{contact.name}}<br>{{offer.url}}<br>{{owner.firstname}}<br>{{owner.lastname}}<br>{{owner.title}}<br>{{owner.phonenumber}}<br>{{owner.email}}<br>{{account.name}}<br>'"
                        />
                        
                        <textarea 
                            v-model="accountEdit.send.body"
                            class="h-64"
                        />
                        
                        
                        
                        
                        
                        
                        
                        <legend class="text-black">{{$t('settings.offerSendOptions.fromSettings')}}</legend>
                        
                        <InputSingleline
                            v-model="accountEdit.emailFromName"
                            :label="$t('settings.offerSendOptions.emailFromName')"
                            :name="'emailFromName'"
                        />
                        
                        <label for="senderId">{{$t('settings.offerSendOptions.smsSenderId')}}</label>
                        <em class="pb-2 block">
                            {{$t('settings.offerSendOptions.smsSenderIdText')}}
                            
                            <div 
                                v-if="! /^[a-zA-Z0-9]{3,11}$/.test( accountEdit.senderId )"
                                class="text-red-600 font-bold my-2">
                                {{$t('settings.offerSendOptions.smsSenderIdValidationError')}}
                            </div>
                        </em>
                        
                        <input 
                            type="text"
                            v-model="accountEdit.senderId"
                            id="senderId"
                            pattern="^[a-zA-Z0-9]{3,11}$"
                            class="mt-2"
                        />
                    </section>
                    <section v-else-if="section == 'offerFollowUpOptions'" class="panel">
                        <label for="followupDays">{{$t('settings.offerFollowUpOptions.followUpAutomaticallyAfter')}}</label>
                        <select 
                            v-model="accountEdit.followup.days"
                            id="followupDays">
                            
                            <option 
                                v-for="days in 14"
                                :key="days"
                                :value="days">
                                
                                {{ $tc('general.days', days) }}
                            </option>
                        </select>
                        
                        <InputSingleline
                            v-model="accountEdit.followup.subject"
                            :label="$t('settings.offerFollowUpOptions.emailSubject')"
                            :name="'followupSubject'"
                        />
                        
                        <!-- <InputMultiline 
                            v-model="accountEdit.followup.body"
                            :label="$t('settings.offerFollowUpOptions.messageBody')"
                            :name="'followupBody'"
                            classes="h-64"
                        /> -->
                        
                        
                        <label>
                            {{ $t('settings.offerFollowUpOptions.messageBody') }}
                            
                            <i 
                                class="fas fa-question-circle link" 
                                @click="helpSection != 'offerMessageBody' ? helpSection = 'offerMessageBody' : helpSection = null;"
                            />
                        </label>
                        
                        <div 
                            v-if="helpSection == 'offerMessageBody'"
                            class="helpSection"
                            v-html="$t('settings.offerSendOptions.offerMessageBodyHelp')+'<br>{{contact.name}}<br>{{offer.url}}<br>{{owner.firstname}}<br>{{owner.lastname}}<br>{{owner.title}}<br>{{owner.phonenumber}}<br>{{owner.email}}<br>{{account.name}}<br>'"
                        />
                        
                        <textarea 
                            v-model="accountEdit.followup.body"
                            class="h-64"
                        />
                        
                        
                        
                        <label for="hibernateDays">{{$t('settings.offerSettings.hibernateOfferIfOlderThan')}}</label>
                        <em class="pb-2 block">{{$t('settings.offerSettings.hibernatedText')}}</em>
                        <select 
                            v-model="accountEdit.hibernate.days"
                            id="hibernateDays">
                            
                            <option 
                                v-for="days in 14"
                                :key="days"
                                :value="days">
                                
                                {{$tc('general.days', days)}}
                                
                                <!-- {{days}} {{days == 1 ? 'day' : 'days'}} -->
                            </option>
                        </select>
                        
                        
                        
                    </section>
                    <section v-else-if="section == 'offerExpireOptions'" class="panel">
                        <label for="expireDays">{{$t('settings.offerExpireOptions.expireAfter')}}</label>
                        <select 
                            v-model="accountEdit.expire.days"
                            id="expireDays">
                            
                            <option 
                                v-for="days in 14"
                                :key="days"
                                :value="days">
                                
                                {{ $tc('general.days', days) }}
                            </option>
                        </select>
                        
                        <label for="remindDaysBefore">{{$t('settings.offerExpireOptions.remindDaysBefore')}}</label>
                        <select 
                            v-model="accountEdit.expire.remindDaysBefore"
                            id="remindDaysBefore">
                            
                            <option 
                                v-for="days in 5"
                                :key="days"
                                :value="days">
                                
                                {{ $tc('general.days', days) }} {{ $tc('general.before').toLowerCase() }}
                            </option>
                        </select>
                        
                        
                        <InputSingleline
                            v-model="accountEdit.expire.subject"
                            :label="$t('settings.offerExpireOptions.emailSubject')"
                            :name="'expireSubject'"
                        />
                        
                        
                        <label>
                            {{ $t('settings.offerExpireOptions.messageBody') }}
                            
                            <i 
                                class="fas fa-question-circle link" 
                                @click="helpSection != 'offerMessageBody' ? helpSection = 'offerMessageBody' : helpSection = null;"
                            />
                        </label>
                        
                        <div 
                            v-if="helpSection == 'offerMessageBody'"
                            class="helpSection"
                            v-html="$t('settings.offerSendOptions.offerMessageBodyHelp')+'<br>{{contact.name}}<br>{{offer.url}}<br>{{owner.firstname}}<br>{{owner.lastname}}<br>{{owner.title}}<br>{{owner.phonenumber}}<br>{{owner.email}}<br>{{account.name}}<br>'"
                        />
                        
                        <textarea 
                            v-model="accountEdit.expire.body"
                            class="h-64"
                        />
                        
                    </section>
                    <section v-else-if="section == 'offerFeedbackOptions'" class="panel">
                        <legend>{{$t('settings.offerFeedbackOptions.whenAcceptingTheOffer')}}</legend>
                        
                        <InputMultiline
                            v-model="accountEdit.feedback.accept.text"
                            :label="$t('settings.offerFeedbackOptions.responseAfterClickingAcceptButton')"
                            :name="'afterAcceptButtonClick'"
                        />
                        
                        <InputSingleline
                            v-model="accountEdit.feedback.accept.subject"
                            :label="$t('settings.offerFeedbackOptions.emailSubject')"
                            :name="'subjectAccept'"
                        />
                        
                        <InputMultiline 
                            v-model="accountEdit.feedback.accept.body"
                            :label="$t('settings.offerFeedbackOptions.messageBody')"
                            :name="'bodyAccept'"
                            classes="h-64"
                        />
                        
                        <legend>{{$t('settings.offerFeedbackOptions.whenDecliningTheOffer')}}</legend>
                        
                        <InputMultiline
                            v-model="accountEdit.feedback.decline.text"
                            :label="$t('settings.offerFeedbackOptions.responseAfterClickingDeclineButton')"
                            :name="'afterDeclineButtonClick'"
                        />
                        
                        <InputSingleline
                            v-model="accountEdit.feedback.decline.subject"
                            :label="$t('settings.offerFeedbackOptions.emailSubject')"
                            :name="'subjectDecline'"
                        />
                        
                        <InputMultiline 
                            v-model="accountEdit.feedback.decline.body"
                            :label="$t('settings.offerFeedbackOptions.messageBody')"
                            :name="'bodyDecline'"
                            classes="h-64"
                        />
                        
                    </section>
                    <section v-else-if="section == 'messageAndChatOptions'" class="panel">
                        <!-- <label>{{$t('settings.messageAndChatOptions.contactPicture')}}</label>
                        
                        <div 
                            v-if="accountEdit.messages.contact.image"
                            class="w-full border p-8 object-contain bg-gray-100 relative">
                            
                            <img 
                                :src="fileToUrl(accountEdit.messages.contact.image)"
                                :alt="$t('settings.messageAndChatOptions.contactPicture')"
                                class="w-full object-contain h-32"
                            />
                            
                            <div class="absolute top-0 right-0 m-2">
                                <a 
                                    :href="fileToUrl(accountEdit.messages.contact.image)"
                                    target="_blank"
                                    download>
                                    
                                    <i 
                                        class="bg-white p-2 fas fa-download" 
                                        :title="$t('general.download')"
                                    />
                                </a>
                                <i 
                                    class="bg-white p-2 fas fa-trash ml-2"
                                    :title="$t('general.remove')"
                                    @click="accountEdit.messages.contact.image = null"
                                />
                            </div>
                        </div>
                        <InputUpload
                            v-else
                            :name="'messageimage'"
                            :multiple="false"
                            @input="onMessageImageAdded($event)"
                        />
                        
                        <InputSingleline
                            v-model="accountEdit.messages.contact.name"
                            :label="$t('settings.messageAndChatOptions.contactPersonName')"
                            :name="'contactPersonName'"
                        />
                        
                        <InputSingleline
                            v-model="accountEdit.messages.contact.title"
                            :label="$t('settings.messageAndChatOptions.contactPersonTitle')"
                            :name="'contactPersonName'"
                        /> -->
                        
                        
                        <!-- welcomeMessage -->
                        <label>
                            {{ $t( 'settings.messageAndChatOptions.welcomeMessage' ) }}
                            
                            <i 
                                class="fas fa-question-circle link" 
                                @click="helpSection = 'welcomeMessage'; iframeScroll('section_welcomeMessage')"
                            />
                        </label>
                        
                        <div 
                            v-if="helpSection == 'welcomeMessage'"
                            class="helpSection">
                            
                            {{ $t( 'settings.messageAndChatOptions.welcomeMessageHelp' ) }}
                        </div>
                        
                        <textarea 
                            v-model="accountEdit.messages.welcomeMessage"
                            class="h-64"
                        />
                    </section>
                </div>
            </form>
            
            <div 
                v-if="showSetupPreview"
                v-show="
                    !section ||
                    section == 'accountDetails' ||
                    section == 'contactDetails' ||
                    section == 'branding' ||
                    section == 'localeSettings' ||
                    section == 'messageAndChatOptions' ||
                    section == 'offerSettings'
                "
                class="flex-none bg-white h-full"
                style="width: 30rem;">
                
                <iframe 
                    v-if="src"
                    class="bg-white overflow-hidden h-full w-full"
                    :src="src"
                    ref="iframeDesign"
                />
            </div>
        </div>
        <div 
            v-if="showSettingsNavigation"
            class="flex-none w-full h-full md:hidden fixed inset-0 mt-12"
            @click="showSettingsNavigation = false">
            
            <transition 
                name="fade-in"
                appear>
                
                <div class="opacity-25 bg-black absolute inset-0" />
            </transition>
            
            <transition 
                name="slide-in"
                appear>
                
                <div 
                    class="px-8 py-4 bg-white shadow-xl absolute top-0 bottom-0"
                    style="line-height: 1.65rem;">
                    
                    <div 
                        v-for="mainSection in sections"
                        :key="'subSections_'+mainSection.name">
                        
                        <h3 class="text-black">{{mainSection.name}}</h3>
                        
                        <nav class="mb-8">
                            <router-link 
                                v-for="sectionCode in mainSection.subSections"
                                :key="'subSections_'+mainSection.name+'_'+sectionCode"
                                :to="{ name: 'settings-section', params: {section: sectionCode} }">
                                
                                {{ $t( 'settings.'+sectionCode+'.heading' )}}
                            </router-link>
                        </nav>
                    </div>
                    
                    <div
                        v-if="appVersion && appVersion.version"
                        class="opacity-50 text-xs">
                        
                        Version {{appVersion.version}} {{appVersion.stage}}
                    </div>
                    
                    <div 
                        v-if="getUser"
                        class="">
                        
                        <div class="text-black truncate w-64">
                            {{ getUser.attributes.email }}
                        </div>
                        
                        <button 
                            @click="signOut()"
                            class="link">
                            
                            {{$t('auth.signOut')}}
                        </button>
                    </div>
                    
                </div>
                
            </transition>
        </div>
    </div>
</template>

<script>
    import Users from '@/components/users/Users.vue';
    import AuthMixins from '@/mixins/AuthMixins';
    // import { mapGetters } from 'vuex'
    import { I18n } from '@aws-amplify/core';
    
    // let moment = require('moment-timezone');
    // let timezones = moment.tz.names();
    // var offsetTmz = [];
    // 
    // for(var i in timeZones){
    //     offsetTmz.push(" (GMT"+moment.tz(timeZones[i]).format('Z')+")" + timeZones[i]);
    // }
    // 
    // import Verte from 'verte';
    // import 'verte/dist/verte.css';
    import VSwatches from 'vue-swatches';
    import 'vue-swatches/dist/vue-swatches.css';

    export default {
        components: {
            VSwatches,
            Users,
            'Integrations': () => import('@/components/integrations/Integrations.vue'),
        },

        mixins: [
            AuthMixins,
        ],
        
        computed: {
            getUser(){
                return this.$store.getters.getUser;
            },
            
            account(){
                return this.$store.getters.getAccount;
            },
            
            accountDefault(){
                return this.$store.getters.getAccountDefault;
            },
            
            section(){
                if (this.$route && this.$route.params.section) {
                    const sectionFromUrl = this.$route.params.section;
                    return sectionFromUrl
                }
                return null;
            },
            
            src(){
                if (this.accountEdit) {
                    return '/d/'+ this.designId +'/i.html?a='+this.accountEdit.accountId+'&tempId='+this.tempId;
                }
                return null;
            },
        },
        
        data(){
            return {
                showRequiredWarning: false,
                loading: false,
                accountEdit: null,
                showSettingsNavigation: false,
                designId: 1,
                previewWidth: 'mobile',
                tempId: 'dummyOffer',
                showSetupPreview: false,
                sections: [
                    {
                        name: this.$t('settings.account'),
                        subSections: [
                            'accountDetails',
                            'contactDetails',
                            'branding',
                            'localeSettings',
                            // 'general',
                            'messageAndChatOptions',
                            'users',
                            'integrations',
                        ]
                    },
                    {
                        name: this.$t('settings.offers'),
                        subSections: [
                            'offerSettings',
                            'offerSendOptions',
                            'offerFollowUpOptions',
                            'offerExpireOptions',
                            'offerFeedbackOptions',
                        ]
                    },
                ],
                appVersion: null,
                helpSection: null
            }
        },
        
        
        watch: {
            accountEdit: {
                // Will fire as soon as the component is created
                immediate: true,
                deep: true,
                handler(accountEdit) {
                    this.writeLocalStorage('accountEdit');
                }
            },
        },
        
        methods: {
            checkVideoUrl(){
                if (
                    this.accountEdit.video.includes('vimeo') &&
                    !this.accountEdit.video.includes('player.vimeo.com') ){
                    
                    const videoId = this.accountEdit.video.split('/').reverse()[0];
                    this.accountEdit.video = 'https://player.vimeo.com/video/'+videoId+'?title=0&byline=0&portrait=0';
                }
                else if (
                    this.accountEdit.video.includes('youtube') &&
                    !this.accountEdit.video.includes('embed') ){
                    
                    let videoId = this.accountEdit.video.split('v=')[1];
                    const ampersandPosition = videoId.indexOf('&');
                    
                    if(ampersandPosition != -1) {
                        videoId = videoId.substring(0, ampersandPosition);
                    }
                    
                    console.log(videoId);
                    
                    this.accountEdit.video = 'https://www.youtube.com/embed/'+videoId;
                }
                
                // this.$notify({ type: 'success', text: this.$t('settings.savedSettings') });
            },
            
            onChangeLocale( language ){
                this.$i18n.locale = language;
                switch (language) {
                    case 'nb':
                        this.$moment.locale('nb');
                        break;
                    case 'en':
                        this.$moment.locale('en');
                        break;
                    default:
                        this.$moment.locale('en');
                }
            },
            
            toggleSettingsNavigation(){
                this.showSettingsNavigation = !this.showSettingsNavigation;
            },
            
            onImageAdded( file ){
                // console.log('file', file);
                this.accountEdit.brand.logo = file;
            },
            
            onMessageImageAdded( file ){
                // console.log('file', file);
                this.accountEdit.messages.contact.image = file;
            },
            
            createAccountCopy(){
                // console.log('createAccountCopy');
                
                this.accountEdit = JSON.parse(
                    JSON.stringify( 
                        Object.assign(
                            {},
                            this.accountDefault,
                            this.account,
                        )
                    )
                );
            },
            
            async updateAccount() {
                try {
                    this.loading = 'update';
                    
                    if (
                        !this.accountEdit.name ||
                        this.accountEdit.name == '') {
                        
                        throw Error('Your account need a name');
                    }
                    
                    if (
                        !this.accountEdit.senderId ||
                        this.accountEdit.senderId == '') {
                            
                        // v-if="! /^[a-zA-Z0-9]{3,11}$/.test( accountEdit.senderId )"
                        this.accountEdit.senderId = this.accountEdit.name;
                    }
                    
                    if (
                        !this.accountEdit.emailFromName ||
                        this.accountEdit.emailFromName == '') {
                        
                        this.accountEdit.emailFromName = this.accountEdit.name;
                    }
                    
                    await this.$store.dispatch('updateAccount', this.accountEdit);
                    this.$notify({ type: 'success', text: this.$t('settings.savedSettings') });
                } 
                catch (e) {
                    this.$notify({ 
                        type: 'error', 
                        title: 'Error', 
                        text: this.$t('settings.problemSaving')
                    });
                    console.error(e);
                }
                finally {
                    this.loading = null;
                }
            },
            
            
            
            writeLocalStorage( eventTrigger ){
                
                if (!this.accountEdit || !this.accountEdit.offer) {
                    return
                }
                
                const data = {
                    ...this.accountEdit.offer,
                    products: [
                        {
                            priceFormated: this.currencyFormat( 10, true, this.accountEdit.locale ),
                            attachments: [
                                {
                                    name: this.$t('settings.dummyFields.product.attachmentName')
                                }
                            ],
                            description: this.$t('settings.dummyFields.product.description'),
                            isDateProduct: false,
                            dates: [],
                            cover: null,
                            coverUrl: "",
                            priceQuantityFormated: this.currencyFormat( 100, true, this.accountEdit.locale ),
                            priceCost: null,
                            price: 20,
                            qty: 5,
                            name: this.$t('settings.dummyFields.product.name'),
                            url: this.$t('settings.dummyFields.product.url')
                        }
                    ],
                    customer: {
                        isCompany: false,
                    },
                    contact: {
                        name: this.$t('settings.dummyFields.johnDoe'),
                    },
                    account: {
                        ...this.accountEdit,
                    },
                    endpoint: null,
                    priceTotalFormated: this.currencyFormat( 100, true, this.accountEdit.locale ),
                    priceTotalAdjustDiscount: 0,
                    priceTotalAdjustDiscountRounded: 0,
                    show: {
                        product:{
                            priceFormated:true,
                            qty: true,
                            discount: true,
                            priceQuantityFormated: true,
                        },
                        priceTotalAdjustDiscount: true,
                    },
                    showCustomer: true,
                    status: 'draft'
                };
                
                localStorage[this.tempId] = JSON.stringify(data);
                
                // if (this.$refs.iframeDesign ) {
                //     console.log('reload ref');
                //     this.$refs.iframeDesign.contentWindow.location.reload();
                // }
                // else {
                //     console.log('no ref...');
                // }
            },
            
            iframeScroll( sectionId ){
                // window.frames['usercomments'].document.getElementById("showall0").scrollIntoView();
                this.$nextTick( () => {
                    
                    const iframeDesign = this.$refs.iframeDesign;
                    if (iframeDesign) {
                        // console.log('trigger sectionId', sectionId);
                        iframeDesign.contentDocument.querySelectorAll('.pulseAnimation').forEach(e => e.classList.remove('pulseAnimation') );
                        
                        iframeDesign.contentDocument.getElementById( sectionId ).scrollIntoView( {
                            behavior: 'smooth', 
                            block: "center", 
                            inline: "center"
                        });
                        
                        iframeDesign.contentDocument.getElementById( sectionId ).classList.add('pulseAnimation');
                    }
                });
            }
        },
        
        mounted(){
            if ( !this.section ) {
                this.$router.push({ name: 'settings-section', params: {section: 'accountDetails'} });
            }
            
            this.createAccountCopy();
            
            this.appVersion = {
                version: process.env.VUE_APP_VERSION,
                stage: process.env.NODE_ENV == 'development' ? 'dev' : '',
            };
            
            
            
            // GIVVIGO:KNEKD24KV8VIF
            this.writeLocalStorage('mounted');
            
            if ( this.isMobile ) {
                this.showSetupPreview = false;
            }
            else if (document.documentElement.clientWidth > 1000 ) {
                this.showSetupPreview = true;
            }
            else {
                this.showSetupPreview = false;
            }
        },
        
        // destroyed(){
        //     // console.log('destroyed');
        //     localStorage.removeItem( this.tempId );
        // } 
    }
</script>

<style lang="scss" scoped>
    .panel {
        // @apply pt-4;
    }

    nav {
        @apply flex;
        @apply flex-col;
        @apply items-start;
        
        a {
            @apply my-1;
            
            &:hover:not(.router-link-active) {
                @apply text-black;
            }
            
            &.router-link-active {
                @apply text-cyan;
            }
        }
    }
</style>
